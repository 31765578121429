import React from 'react';

import { AnalyticsProvider, useAnalytics } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';
import { StaticFooter } from 'reaxl-footer';

import globalAnalyticsHandlers from '@/analytics/globalAnalyticsHandlers';
import { withDataLayer } from '@/analytics/withDataLayer';

const EnhancedAnalyticsProvider = withDataLayer(AnalyticsProvider);

function Footer() {
    const {
        col_enable_one_trust_footer: [enableOneTrustFooter],
    } = useFeatures([
        'col_enable_one_trust_footer',
    ]);

    const { sendClick } = useAnalytics();

    const handleFooterClick = (event, data = {}) => {
        sendClick('footerLinkClick', event, data);
    };

    return (
        <StaticFooter
            onClick={handleFooterClick}
            enableOneTrustFooter={enableOneTrustFooter}
        />
    );
}

function AtcFooter() {
    return (
        <EnhancedAnalyticsProvider
            value={globalAnalyticsHandlers}
        >
            <Footer />
        </EnhancedAnalyticsProvider>
    );
}

export default AtcFooter;
